import { useSelector } from 'react-redux';
import headerHeights from 'config/branding/header-heights';
import useDynamicMainContentTop from '@activebrands/core-web/hooks/useDynamicMainContentTop';
import { styled } from '@activebrands/core-web/libs/styletron';
import { LayoutProps } from '@activebrands/core-web/types/layout';
import Header from 'components/Header';
import Banner from 'components/Header/Banner';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

const Main = styled('main', {
    flex: '1 0 auto',
});

const CheckoutLayout = ({ children, page = {} }: LayoutProps) => {
    const backToSiteLabel = page.data?.content?.backToSiteLabel;
    const banner = page.data?.content?.banner?.[0] || {};
    const bannerTexts = banner?.texts?.map(text => text?.richText || text);
    const hasBanner = bannerTexts?.length > 0;
    const { headerHeight } = headerHeights;
    const basketItems = useSelector((state: any) => state.basket.items);
    const numberOfItems = basketItems?.reduce((sum, item) => (sum += item.quantity), 0);
    const isEmptyCheckoutView = numberOfItems < 1;

    // Add extra styling for banner if there is only one text
    const bannerStyling = {
        flipClockItemStyle: { whiteSpace: 'wrap' },
        flipClockWrapperStyle: { height: 'auto', padding: '8px', gap: '12px' },
    };

    // Banner styling
    type BannerStyling = { flipClockItemStyle?: any; flipClockWrapperStyle?: any };
    const bannerStyle: BannerStyling = bannerTexts?.length === 1 ? bannerStyling : {};

    // Calculate the distance from the top of the page for content
    const mobileMainContentTop = '0';
    const desktopMainContentTop = isEmptyCheckoutView ? '0px' : `${headerHeight}px`;

    // Update the distance from the top of the page for content
    useDynamicMainContentTop({ desktopMainContentTop, mobileMainContentTop });

    return (
        <Wrapper $style={{ marginTop: [mobileMainContentTop, null, null, null, null, desktopMainContentTop] }}>
            <Header
                isCheckout
                backToSiteLabel={backToSiteLabel}
                isEmptyCheckout={isEmptyCheckoutView}
                theme="default"
            />
            {hasBanner && (
                <Banner
                    $flipClockItemStyle={bannerStyle?.flipClockItemStyle}
                    $flipClockWrapperStyle={bannerStyle?.flipClockWrapperStyle}
                    spin={banner?.spin}
                    texts={bannerTexts}
                    theme={banner.theme}
                    to={banner.link?.url}
                />
            )}
            <Main>{children}</Main>
        </Wrapper>
    );
};

export default CheckoutLayout;
